:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;

}


.maindivfeature{
    width: 100%;
    min-height: 50vh;
    background-color: var(--Background-3);
    position: relative;
    padding: 2rem;
    box-sizing: border-box;
}
.Featuredheading{
    font-size: var(--heading-h2-font-size);
    margin: 0 0 2% 2%;
}

.text h3{
    color: var(--secondarycolor);
    font-size: var(--heading-h3-font-size);
    font-weight: bold;
}
.cardtitle{
    display: flex;
    justify-content: space-between;
}
.price{
    color: var(--secondarycolor);
    font-size: var(--heading-h3-font-size);
    font-weight: bold;
    margin: 2%;
}

.text p{
    color: var(--secondary);
    font-size: var(--heading-h5-font-size);
}

@media(max-width:400px){
    .backgorundclour{
        background-color: var(--Background-3);
    }
}