:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}
.cateringoptionmain{
    background-color: var(--Background-1);
    width: 100%;
    height: 90%;
    margin-top: 2%;
    padding: 2rem;
    position: relative;
    overflow: visible; 
    z-index: 1;
}
.cateringheading {
    font-size: var(--heading-h1-font-size);
    color: var(--secondary-text);
    text-align: center;
    margin-bottom: 2rem;
    width: 100%;
    margin-top: 5%;
    /* background-color: red; */
}



/* card */
.card-container {
    width: 400px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
}

.card-caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    text-align: left;
    background-color: var(--secondarycolor);
    color: white;
    font-size: 18px;
    font-weight: bold;
}
.carousel-item-padding {
      padding: 0 20px; /* Increased gap for tablet mode */
    }
/* coursel */
@media (max-width: 1024px) and (min-width: 464px) {
    .carousel-item-padding {
      padding: 0 20px; /* Increased gap for tablet mode */
    }
}
