:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}

body {
    overflow-x: hidden;
}

.herosectionAmain {
    width: 100%;
    height: 75vh;
    margin: 0;
    padding-top: 0;
    background-color: var(--Background-1);
    z-index: 0;
    position: relative;
}

.herotext h1 {
    font-size: var(--heading-h1-font-size);
    color: var(--secondarycolor);
    padding-left: 5%;
}

.herotext span {
    color: var(--primarycolor);
}

.herotext p {
    font-size: var(--heading-h5-font-size);
    color: var(--secondary-text);
    padding-left: 5%;
}

.heroimage img {
    width: 20vw;
    margin-left: 20%;
    z-index: 5;
    position: absolute;
    top: 20%;
}

.small-width-description {
    max-width: 60%;
}

.textgap {
    margin-top: 10rem;
}

.coffebeans1 {
    position: absolute;
    top: -20px;
    right: 60px;
    background-image: url('../Images/coffebeans2.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 30vw;
    height: 45vh;
    z-index: 1;
}

.coffebeans2 {
    position: absolute;
    bottom: 10px;
    left: 0;
    background-image: url('../Images/coffebeans2.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 30vw;
    height: 20vh;
    z-index: 1;
    
}

.herobtn {
    margin-top: 1rem;
    padding-left: 5%;
}

.menubtn, .locationbtn, .visitbtn {
    padding: 0.75rem 1.5rem;
    margin-right: 1rem;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--secondarycolor);
    color: var(--secondarycolor);
    border-radius: 15px;
    z-index: 3;
}

.menubtn:hover, .locationbtn:hover, .visitbtn:hover {
    background-color: var(--secondarycolor);
    color: var(--primarycolor);
}

/* Responsive Styles */
@media (max-width: 1280px) {
    .coffebeans1 {
        width: 45vw;
        height: 32vh;
        top: 60px;
        right: 5px;
    }

    .coffebeans2 {
        display: none;
    }

    .heroimage img {
        width: 30vw;
        margin-left: 10%;
    }
}

@media (max-width: 1025px) {
    .herosectionAmain {
        height: 90%;
        padding: 5% 0;
    }
    .heroimage img {
        width: 20vw;
        margin-left: 5%;
        z-index: 5;
        position: absolute;
        top: 5%;
    }

    .herotext {
        margin-top: -150px;
        padding-top: 0;
    }

    .heroimage img {
        width: 30vw;
        margin-left: 5%;
    }

    .coffebeans1 {
        width: 35vw;
        height: 20vh;
        top: -140px;
        right: 5px;
    }
}
@media(max-width:1219px){
    .coffebeans1 {
        width: 35vw;
        height: 40vh;
        top: -60px;
        right: 5px;
        opacity: 0.5;
    }

}

@media (max-width: 920px) {
    .herosectionAmain {
        height: 90%;
        padding: 5% 0;
    }
    .heroimage img {
        width: 10vw;
        margin-left: 5%;
        z-index: 5;
        position: absolute;
        top: 2%;
    }

    .coffebeans1 {
        width: 45vw;
        height: 32vh;
        top: -130px;
        right: 5px;
    }
}

@media (max-width: 915px) {
    .heroimage img {
        width: 45vw;
        margin-left: 2%;
    }
}

@media (max-width: 850px) {
    .herosectionAmain {
        height: 90%;
        padding: 2% 3%;
    }
    .herotext {
        margin-top: 0;
        padding-top: 0;
    }
    .small-width-description {
        max-width: 100%;
    }

    .textgap {
        margin-top: 0.1rem;
    }

    .coffebeans1, .coffebeans2 {
        display: none;
    }

    .heroimage img {
        display: none;
    }
}

@media (max-width: 768px) {
    .herosectionAmain {
        height: 95%;
        padding: 5% 0;
    }

    .small-width-description {
        max-width: 100%;
    }

    .textgap {
        margin-top: 0.1rem;
    }
}

@media (max-width: 700px) {
    .herosectionAmain {
        height: 90%;
        padding: 30% 0;
    }
    .herotext {
        margin-top: -120px;
        padding-top: 0;
    }

    .small-width-description {
        max-width: 100%;
    }

    .textgap {
        margin-top: 0.1rem;
    }
    
}


