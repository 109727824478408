:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}

.menu {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: var(--heading-h1-font-size);
}

.menu-container1 {
    width: 100%;
    height: auto;
    padding: 2rem 0;
}

.menu-container1 h2 {
    font-size: var(--heading-h2-font-size);
    margin-top: 2rem;
    color: var(--secondary-text);
    text-align: start;
    position: relative; 
    border-bottom: 5px solid var(--primarycolor);
    width: 25%;
}

.menu-background {
    background-color: var(--Background-1);
    margin-top: 5%;
    width: 100%;
    height: auto;
    border-radius: 0% 100% 0% 100% / 100% 45% 55% 0%;
    background-image: url("../Images/coffecupbg.png");
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}
.menu-background1 {
    background-color: var(--Background-1);
    margin-top: 5%;
    width: 100%;
    height: auto;
    border-radius: 100% 0% 100% 0% / 46% 100% 0% 54%;
    background-image: url("../Images/coffecupbg.png");
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.menu-inner-background {
    background-color: var(--Background-3);
    width: 85%;
    height: auto;
    margin: 0% 5%;
    border-radius: 20px;
    padding: 2rem;
}

.menu-card {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    transition: transform 0.3s ease;
}

.menu-card:hover {
    transform: scale(1.05);
}

.menu-card-inner {
    display: flex;
    justify-content: space-between;
    margin: 0 2%;
    font-size: var(--heading-h3-font-size);
    color: var(--secondary-text);
}

.menu-card p {
    font-size: var(--heading-h4-font-size);
    color: #2B231D;
    margin: 0 2%;
}

.menu-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
}

@media screen and (min-width: 640px) {
    .menu-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    

}

@media screen and (min-width: 768px) {
    

    .menu-inner-background {
        width: 85%; /* Keep inner background within a good size */
    }
}

@media screen and (min-width: 1024px) {
    .menu-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .menu-container1 h2 {
        font-size: 2.5rem;
    }


}

@media(max-width:600px){
    .menu-container1 h2 {
        width: 90%;
        margin-left: 2%;
    }

    .menu-background {
        height: auto; /* Allow height to adjust for smaller screens */
        background-size: cover; /* Ensure background image covers the area */
    }
}
