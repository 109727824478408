@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --primarycolor:#FF902A;
  --secondarycolor:#2F2105;
  --Background-1:#F6EBDA;
  --Background-2:#F9D9AA;
  --secondary-text:#7E7D7A;
  --Background-3:#FAFAFA;
  --border-color1: rgba(249, 217, 170, 0.5);
  --border-color2: rgba(246, 235, 218, 0.5); 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  background-color: var(--Background-3);
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  overflow-x: hidden; /* Add overflow-x: hidden here too */
}
