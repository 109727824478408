
:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;

}
.coffeebg {
    display: none;
    position: absolute;
    top: 0;
    right: -100px;
    background-image: url('../Images/backgroundbeans.png');
    background-size: 100% 45vh;
    background-repeat: no-repeat;
    width: 30vw;
    height: 95%;
    padding: 5% 0;
    z-index: -1; 
}
/* Main Section Styling */
.catering-main {
    background-color: var(--Background-1);
    z-index: 1;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    height: 95%;
    padding: auto;
}

.catering-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10%;
    height: 60vh;
    position: relative;
    z-index: 2;
    flex-wrap: nowrap; /* No wrapping on large screens */
}

/* Content Styles */
.catering-content {
    max-width: 50%;
    position: relative;
    z-index: 3;
}

.catering-content h1 {
    font-size: var(--heading-h1-font-size);
    color: var(--secondarycolor);
    /* font-weight: bold; */
}
.catering-content p {
    font-size: var(--heading-h4-font-size);
    color: var(--secondarycolor);
    /* font-weight:normal; */
}


.highlight {
    color: var(--primarycolor);
}

.catering-buttons {
    margin-top: 1.5rem;
    cursor: pointer;
}

.catering-menu-btn,
.catering-location-btn {
    padding: 0.75rem 1.5rem;
    margin-right: 1rem;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--secondarycolor);
    color: var(--secondarycolor);
    border-radius: 15px;
}

.catering-menu-btn:hover,
.catering-location-btn:hover {
    background-color: var(--secondarycolor);
    color: var(--primarycolor);
}

/* Image Grid Styling */
.catering-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Keep 3 columns for large screens */
    gap: 10px;
    max-width: 40%;
    z-index: 3;
}

.grid-image {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
    .catering-main{
        height: 100%;
        padding-bottom: 20% ;
    }
    
    .catering-container {
        flex-wrap: wrap; /* Allow wrapping for smaller screens */
        justify-content: center; /* Center the content */

        
    }

    .catering-content {
        max-width: 100%; /* Allow the content to take full width */
        margin-bottom: 20px;
    }

    .catering-image-grid {
        max-width: 100%; /* Images take full width on smaller screens */
        grid-template-columns: repeat(2, 1fr); /* Use 2 columns for smaller screens */
    }

    .grid-image {
        max-height: 150px; /* Reduce image height for smaller screens */
    }
}

/* @media (max-width: 768px) {
    .catering-container {
        flex-direction: column;
    }

    .grid-image {
        max-height: 120px;
    }
} */

@media (max-width: 590px) {
    .catering-image-grid {
        display: none;
    }
    .catering-main{
        height: 100%;
        padding-bottom: 5% ;
    }
    .coffeebg {
        
        position: absolute;
        top:20px;
        right: 20px;
        width: 80vw;
        height: 30vh;
        background-size: cover;
        opacity: 0.3;
        display: block; 
    }

    .catering-container {
        margin: 0 5%;
    }

    .catering-content h1 {
        font-size: 1.5rem;
    }

    .catering-content p {
        font-size: 0.875rem;
    }



    .catering-image-grid {
        grid-template-columns: repeat(1, 1fr); /* Single column for mobile */
        gap: 5px;
    }

    .grid-image {
        max-height: 100px; /* Further reduce image height for mobile */
    }
}
