:root {
  --primarycolor: #FF902A;
  --secondarycolor: #2F2105;
  --Background-1: #F6EBDA;
  --Background-2: #F9D9AA;
  --secondary-text: #7E7D7A;
  --Background-3: #FAFAFA;
  --border-color1: rgba(249, 217, 170, 0.5);
  --border-color2: rgba(246, 235, 218, 0.5);
  --heading-h1-font-size: 2.75rem;
  --heading-h2-font-size: 2rem;
  --heading-h3-font-size: 1.5rem;
  --heading-h4-font-size: 1.125rem;
  --heading-h5-font-size: 1rem;
}
  
  .maintestimonaldiv {
    width: 100%;
  }
  
  .customersays {
    width: 35vw;
    margin:10% 25%;

  }
  
  .descriptiontext {
    max-width: 100%;
  }
  
  .customercardcoursel {
    margin: 0;
    padding: 1rem;
  }
  
  .customerreview {
    width: 100%;
    gap: 0.1rem; 
  }
  
  .maintestimonal {
    width: 100%;
    min-height: 50vh;
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 0 50px 50px 0;
    z-index: 1;
  }
  
  .maintestimonal::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
    height: 100%;
    background-color: var(--Background-1);
    border-radius: 0 50px 50px 0;
    z-index: -1;
  }
  
  .bgtestimonal {
    width: 50vw;
    height: 40vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('../Images/coffecupbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.9;
  }
  
  .text h3 {
    color: var(--secondarycolor);
    font-size: var(--heading-h3-font-size);
  }
  
  .text p {
    color: var(--secondary);
    font-size: var(--heading-h5-font-size);
  }
  

@media (max-width:600px){
    .customersays{
        width: 70vw;
        margin: 0 5%;
    }
}