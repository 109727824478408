:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;

}

nav{
    
    z-index: 10;
    position: relative;
  }
   ul li{
    font-weight: 600;
    color: var(--secondarycolor);
    
  }
ul li:hover{
    color: var(--primarycolor);
  }

  ul li a{
    position: relative;
    padding-bottom: 5px;
  }
  ul li a::after{
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--primarycolor);
    bottom: 0;
    transform-origin: left;
    transition-duration: 300ms;
    transform: scaleX(0);
  }
  ul li a:hover::after{
    transform-origin: left;
    transform: scaleX(1);
  }

  .callBtn{
    width: 140px;
    height: 45px;
    border: 2px solid var(--secondarycolor);
    background-color: rgba(255, 144, 42);
    text-align: center;
    font-weight: 600;
    font-size: large;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in;
  }

  .callBtn:hover{
    background-color: var(--secondarycolor);
    color: var(--primarycolor);
  }
  
  @media (max-width: 700px) {
    #navbar-sticky {
      background-color: var(--Background-2); 
      border-radius: 10px;
      padding: 5px; 
      transition: all 0.3s ease-in-out; 
    }
  
    ul li:hover {
      /* background-color: var(--secondarycolor);  */
      color: var(--primarycolor); 
    }
    /* .Cusinelogo{
      width: 30vw;
      height: 10vh;
    } */
  }
  
  
  @media (max-width: 820px) and (max-height: 1180px) {
    ul li {
      font-size: 0.9rem; 
    }
  
    .Visitusbtn {
      font-size: 1rem; 
      padding: 8px 16px; 
    }
  
    #navbar-sticky {
      padding: 10px; 
    }
  }
  