:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;

}
.aboutmain{
    background-color: var(--Background-1);
    width: 100%;
    height: auto;
    margin: 0;
    padding-top: 0;
    
}
.mainabout {
    width: 100%;
    min-height: 50vh;
    background-color: var(--Background-1);
    position: relative;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.bgimg {
    width: 100%;
    height: 60vh;
    position: absolute;
    bottom: 30;
    right: 0;
    background-image: url('../Images/coffecupbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.9;
}

h2 {
    font-size: var(--heading-h2-font-size);
    color: var(--secondarycolor);
    margin-bottom: 1rem;
}
span{
    color: var(--primarycolor);
}
.provide{
    font-size: var(--heading-h3-font-size);
    color: var(--secondary-text);
    margin-bottom: 1rem;
}
.descriptiontext{
    font-size: var(--heading-h4-font-size);
    color: var(--secondary-text);
    margin-bottom: 1rem;
}
.cardbtn{
    color: var(--primarycolor);
    border: 1px solid var(--primarycolor);
    border-radius: 5px;
    width: 10vw;
    height: 4vh;
}
.cardbtn:hover{
    background-color: var(--secondarycolor);
    color: var(--primarycolor);
}
@media screen and (max-width: 1290px) {
    .cardbtn {
        width: 40vw; 
        height: 5vh;
    }
    .bgimg {
        height: 80%;
        background-size: cover;
        bottom: 5%;
        right: 0;
        opacity: 0.9;
    }
}
@media screen and (max-width: 915px) {
    .cardbtn {
        width: 30vw; 
        height: 3vh;
    }
    .bgimg {
        height: 80%;
        background-size: cover;
        bottom: 5%;
        right: 0;
        opacity: 0.9;
    }
}
@media screen and (max-width: 768px) {
    .cardbtn {
        width: 30vw;      
        height: auto;
    }
    .bgimg {
        height: 80%;
        background-size: cover;
        bottom: 5%;
        right: 0;
        opacity: 0.9;
    }
}

@media screen and (max-width: 576px) {
    .cardbtn {
        width: 40vw;     
    }
    .bgimg {
        height: 100%;
        background-size: cover;
        bottom: 10%;
        right: 0;
        opacity: 0.9;
    }
}



@media (max-width: 768px) {
    h2 {
        font-size: var(--heading-h3-font-size);
    }

    p {
        font-size: var(--heading-h4-font-size);
    }

    .bgimg {
        height: 90px;
        background-size: cover;
        bottom: 5%;
        right: 0;
        opacity: 0.9;
    }
}

