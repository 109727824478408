.menu-container {
    background-color: var(--Background-3);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    transition: all 0.3s ease-in-out;
  }
  
  .menu-heading {
    text-align: center;
    color: var(--secondarycolor);
    font-size: 1.5rem;
  }
  
  .menu-list {
    padding: 0;
    color: var(--secondary-text);
    font-size: 1rem;
    line-height: 1.8;
  }
  
  .menu-list li {
    padding: 5px 0;
    list-style-type: disc;
    margin-left: 20px;   
    cursor: pointer; 
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .menu-container {
      max-width: 90%;
    }
  
    .menu-heading {
      font-size: 1.25rem;
    }
  
    .menu-list li {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .menu-container {
      max-width: 100%;
      padding: 15px;
    }
  
    .menu-heading {
      font-size: 1.1rem;
    }
  
    .menu-list li {
      font-size: 0.85rem;
    }
  }
  