/* Aboutpagecomponent2 */
:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;

}
.Aboutcmpimg {
    margin-left: 20%;
    width: 30vw;
    height: 55vh;
    z-index: 2; /* Ensure the image is on top */
    padding: 5px; 
    box-sizing: border-box;
    position: relative;
    top: -130px; /* Move the image upward outside the background */
}

.Aboutcmpimg img {
    width: 40vw; 
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 10px solid var(--Background-3);
    background-color: var(--Background-3);
}


/* Aboutpagecomponent2 */
.Aboutpagemain {
    background-color: var(--Background-1);
    width: 100%;
    height: auto;
    margin-top: 10%;
    padding: 2rem;
    position: relative;
    overflow: visible; /* Allow overflow for the image to escape */
    z-index: 1; /* Background behind the image */
}

.Aboutpagemain::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../Images/coffebeans2.png');
    background-size: auto; 
    background-repeat: repeat; 
    background-position: top left;
    opacity: 0.3; 
    z-index: 1;
}

.Aboutpagemaincontent {
    /* background-color: red; */
    margin: 5% 0;
}

.Aboutpagemaincontent h2 {
    font-size: var(--heading-h2-font-size);
    color: var(--secondarycolor);
    font-weight: normal;
}

.Aboutpagemaincontent h3 {
    font-size: var(--heading-h3-font-size);
    color: var(--secondarycolor);
    font-weight: normal;
}

.Aboutpagemaincontent p {
    width:90vw;
    font-size: var(--heading-h5-font-size);
    color: var(--secondary-text);
    font-weight: normal;
    
}
.span1{
    color: var(--secondarycolor);
    border-bottom: 4px solid var(--primarycolor);

}

/* Responsive Styling */
@media (min-width: 768px) {
    .Aboutcmpimg{
        margin-left: 0;
        width: 50vw; 
    }
    .aboutimgradius {
        width: 40vw; /* Make image responsive for larger screens */
    }
    .Aboutpagemaincontent p {
        width: 100%; /* Adjust for medium screens */
    }

    .Aboutpagemaincontent {
        margin-left: 5%;
    }
}

@media (min-width: 1024px) {
    .Aboutpagemaincontent p {
        width: 90vw; 
    }

    .Aboutpagemaincontent {
        margin-left: 10%;
    }
    .Aboutcmpimg img{
        width: 30vw;
        height: 100%;
    }
}
@media(max-width:800px){
    .Aboutcmpimg{
        top: 10px;
        margin-left: 0;
        width: 100%; 
        height: 60vh;
        margin-bottom: 0;
    }
    .Aboutcmpimg img{
        width: 100%;
        height: 100%;
    }
    .Aboutpagemaincontent p{
        /* background-color: red; */
        width: 95%;
    }
}
@media(max-width:785px){
    .Aboutcmpimg{
        
        width: 100%;

    }
    .Aboutcmpimg img{
        
        width: 100%;
        height: 100%;
        top: -100px;
    }
}


@media (min-width: 1024px) {
    .Aboutpagemaincontent p {
        width: 30vw; 
    }
}

.Aboutpagemain img {
    margin: 0 ;
    max-width: 80%; 
}