:root {
    --primarycolor: #FF902A;
    --secondarycolor: #2F2105;
    --Background-1: #F6EBDA;
    --Background-2: #F9D9AA;
    --secondary-text: #7E7D7A;
    --Background-3: #FAFAFA;
    --border-color1: rgba(249, 217, 170, 0.5);
    --border-color2: rgba(246, 235, 218, 0.5);
    --heading-h1-font-size: 2.75rem;
    --heading-h2-font-size: 2rem;
    --heading-h3-font-size: 1.5rem;
    --heading-h4-font-size: 1.125rem;
    --heading-h5-font-size: 1rem;
}

.mainfooter {
    background-color: #473106;;
    width: 100%;
    height: 100%;
    margin-top: 5%;
    position: relative;
}

.footermaininfo {
    padding-bottom: 15%;
    margin-left: 5%;
    padding-top: 3%;
    width: 90%;
}

.footerbottom {
    width: 100%;
    height: 10vh;
    background-color: #110900;
    position: absolute; 
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
}

.footerinfo{
    z-index: 4;
}

.footerbottom p, .footerbottom span {
    color: #FFD057; 
    font-size: 0.7rem;
}

.footerbgimage {
    width: 100%;
    height: 80%;
    position: absolute;
    bottom: 0; /* Adjusted to fit correctly */
    right: 0;
    background-image: url('../Images/coffecupbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.2;
}

.footerinfo h2 {
    font-size: 1.5rem;
    color: #F0DCB6;
    margin-bottom: 1rem;
}

.footerinfop p, .footerinfop a {
    font-size: 1rem;
    color: #F0DCB6;
    text-decoration: none; 
}

.footerimg {
    display: flex;
    position: relative; 
    z-index: 4; 
    /* background-color: red; */
}



.facebook{
    /* background-color: red; */
    width: 5vw;
  
}
.tiktok{
    width: 5vw;
}


@media(max-width:1025px) {
    .footerbgimage {
        height: 50%;
    }
}

@media (max-width:900px) {
    .footermaininfo {
        padding-bottom: 25%;
        margin-left: 5%;
        width: 90%;
    }
    .footerbgimage {
        height: 70%;
    }
}

@media (max-width:500px) {
    .footerbottom p, .footerbottom span {
        font-size: 0.5rem;
    }
}
